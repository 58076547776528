
import { createStore, combineReducers } from 'redux';
import modalReducer from './reducers/modal';

export default () => {
  const store = createStore(
    combineReducers({
      modal: modalReducer,
      modalBanner: modalReducer
    })
  );
  return store;
};
