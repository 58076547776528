import { css } from '@emotion/react'
import theme from './theme'
import reset from './reset'
import 'typeface-montserrat'

const globalStyle = css`
  ${reset}

  body {
    margin: 0;
    color: ${theme.colors.body.color};
    background: ${theme.colors.body.background};
    font-family: ${theme.fontFamily};
    font-size: 1.6rem;
  }

  h1, h2, h3, h4, h5, h6 {
    color: ${theme.colors.primary};
  }
  
  h1 {
    font-size: 3.5rem;
    a, a:hover, a:focus, a:visited {
      text-decoration: none;
      font-weight: inherit;
    }
  }

  h2 {
    font-size: 3rem;
  }

  h3 {
    font-size: 2rem;
  }

  h4 {
    font-size: 1.6rem;
  }

  img {
    margin: 0;
  }

  p {
    font-size: 1.6rem;
    line-height: 2.5rem;
  }

  a {
    color: ${theme.colors.body.color};
    transition: all .2s ease-in-out;
    text-decoration: underline;
    font-weight: 500;
    &:focus,
    &:visited,
    &:active {
      text-decoration: underline;
      color: ${theme.colors.primary};
    }
    &:hover {
      color: ${theme.colors.faded};
    }
  }

  ::selection {
    color: ${theme.colors.body.background};
    background-color: ${theme.colors.body.color};
  }
`

export default globalStyle
