const theme = {
  colors: {
    body: {
      color: "#050000",
      background: "#ffffff"
    },
    primary: "#111111",
    faded: "#979595",
  },
  fontFamily: "Montserrat, sans-serif",
  maxWidth: '1072px',
  maxWidthText: '720px',
  breakpoints: {
    xxs: '320px',
    xs: '420px',
    xsLandscape: '668px',
    s: '775px',
    m: '1054px'
  },
}

export default theme
