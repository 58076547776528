import React from 'react'
import { connect } from 'react-redux'
import { Global, ThemeProvider } from '@emotion/react'
import { theme, globalStyle } from '../styles'

class Layout extends React.Component {

  render() {
    const { children, modalBanner } = this.props;
    
    if (typeof document !== 'undefined') {
      if (modalBanner) {
        document.querySelector('html').style.overflow = 'hidden';
      } else {
        document.querySelector('html').style.overflow = 'auto';
      }
    }

    return (
      <ThemeProvider theme={theme}>
        <Global styles={globalStyle} />
        {children}
      </ThemeProvider>      
    )
  }
  
}
const mapStateToProps = (state) => ({
  ...state.modalBanner
});
export default connect(mapStateToProps, null)(Layout);